import React from 'react';
import CreateFact from '../admin/CreateFact';

const AdminPage = () => {
  return (
    <div>
      <CreateFact />
    </div>
  );
};

export default AdminPage;
