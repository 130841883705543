// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n  box-sizing: border-box;\n}\n\n:root {\n  --AeroBlue: #D6F9DD;\n  --LightGreen: #99F7AB;\n  --YellowGreenCrayola: #ABDF75;\n  --Ebony: #60695C;\n  --Ebony-Dark: #373b34;\n  --SilverSand: #C5D6D8;\n  --OrchidCrayola: #FFB2E6;\n  --TerraCotta: #ED6A5A;\n  --PaleSpringBud: #F4F1BB;\n  --Opal: #9BC1BC;\n  --CadetBlue: #5CA4A9;\n  --Alabaster: #E6EBE0;\n}\n\n.cRvifr9vjfhI4LjkCyxTJg\\=\\= img {\n\n  }\n\n.cRvifr9vjfhI4LjkCyxTJg\\=\\= ._7aKVRVEOWiib3TOsEgl7fg\\=\\= {\n    background-color: var(--TerraCotta);\n  }\n\n.cRvifr9vjfhI4LjkCyxTJg\\=\\= .QaycmaT5hCRGFz-7ceJ8DQ\\=\\= {\n    background-color: var(--YellowGreenCrayola);\n  }\n\n.cRvifr9vjfhI4LjkCyxTJg\\=\\= ._35T6-KkCn67txbO71J\\+X9A\\=\\= {\n    background-color: var(--CadetBlue);\n  }\n\n.cRvifr9vjfhI4LjkCyxTJg\\=\\= .PigROYbjNfTku30wKBESVw\\=\\= {\n    background-color: var(--LightGreen);\n  }\n", "",{"version":3,"sources":["webpack://./src/components/admin/admin.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,6BAA6B;EAC7B,gBAAgB;EAChB,qBAAqB;EACrB,qBAAqB;EACrB,wBAAwB;EACxB,qBAAqB;EACrB,wBAAwB;EACxB,eAAe;EACf,oBAAoB;EACpB,oBAAoB;AACtB;;AAIE;;EAEA;;AAEA;IACE,mCAAmC;EACrC;;AAEA;IACE,2CAA2C;EAC7C;;AAEA;IACE,kCAAkC;EACpC;;AAEA;IACE,mCAAmC;EACrC","sourcesContent":["* {\n  box-sizing: border-box;\n}\n\n:root {\n  --AeroBlue: #D6F9DD;\n  --LightGreen: #99F7AB;\n  --YellowGreenCrayola: #ABDF75;\n  --Ebony: #60695C;\n  --Ebony-Dark: #373b34;\n  --SilverSand: #C5D6D8;\n  --OrchidCrayola: #FFB2E6;\n  --TerraCotta: #ED6A5A;\n  --PaleSpringBud: #F4F1BB;\n  --Opal: #9BC1BC;\n  --CadetBlue: #5CA4A9;\n  --Alabaster: #E6EBE0;\n}\n\n.updateBox {\n\n  img {\n\n  }\n\n  .red {\n    background-color: var(--TerraCotta);\n  }\n\n  .yellow {\n    background-color: var(--YellowGreenCrayola);\n  }\n\n  .blue {\n    background-color: var(--CadetBlue);\n  }\n\n  .green {\n    background-color: var(--LightGreen);\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"updateBox": "cRvifr9vjfhI4LjkCyxTJg==",
	"red": "_7aKVRVEOWiib3TOsEgl7fg==",
	"yellow": "QaycmaT5hCRGFz-7ceJ8DQ==",
	"blue": "_35T6-KkCn67txbO71J+X9A==",
	"green": "PigROYbjNfTku30wKBESVw=="
};
export default ___CSS_LOADER_EXPORT___;
