import React from 'react';
import Bio from '../bio/Bio';
import styles from './Section.css';

const SectionFour = () => {
  return (
    <div className={styles.sectionFour} >
      <Bio />
    </div>
  );
};

export default SectionFour;
