// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ovu2Zlbtl0OlyVpfBtHFIQ\\=\\= {\n min-height: 80rem;\n margin-top: 80vh;\n}\n", "",{"version":3,"sources":["webpack://./src/components/pages/WorkPage.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;CACjB,gBAAgB;AACjB","sourcesContent":[".workPageBox {\n min-height: 80rem;\n margin-top: 80vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workPageBox": "ovu2Zlbtl0OlyVpfBtHFIQ=="
};
export default ___CSS_LOADER_EXPORT___;
