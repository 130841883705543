import React from 'react';
import BioPage from '../pages/BioPage';
import styles from './Section.css';

const SectionFive = () => {
  return (
    <div className={styles.sectionFive} >
      <BioPage />
    </div>
  );
};

export default SectionFive;
