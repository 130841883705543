// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n  box-sizing: border-box;\n  max-width: 100vw;\n  \n}\n\n.iYuxq3y6EZeSvJ81l0Ar\\+w\\=\\= {\n  position: relative;\n  display: block;\n  overflow: hidden;\n  scroll-behavior: smooth;\n  scroll-snap-type: y mandatory;\n}\n", "",{"version":3,"sources":["webpack://./src/components/portfolio/Portfolio.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,gBAAgB;;AAElB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,gBAAgB;EAChB,uBAAuB;EACvB,6BAA6B;AAC/B","sourcesContent":["* {\n  box-sizing: border-box;\n  max-width: 100vw;\n  \n}\n\n.portfolio {\n  position: relative;\n  display: block;\n  overflow: hidden;\n  scroll-behavior: smooth;\n  scroll-snap-type: y mandatory;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"portfolio": "iYuxq3y6EZeSvJ81l0Ar+w=="
};
export default ___CSS_LOADER_EXPORT___;
