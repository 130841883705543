import React from 'react';
import WorkPage from '../pages/WorkPage';
import styles from './Section.css';



const SectionTwo = () => {
  return (
    <div className={styles.sectionTwo} >
      <WorkPage />
    </div>
  );
};

export default SectionTwo;
